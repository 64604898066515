import { template as template_7df1b8c187b349859241fb88f361854e } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_7df1b8c187b349859241fb88f361854e(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
