import { template as template_57274f9b492a417da80c3d85b8328ceb } from "@ember/template-compiler";
const FKLabel = template_57274f9b492a417da80c3d85b8328ceb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
