import { template as template_83f3139c928349b0948cd85b603306da } from "@ember/template-compiler";
const FKControlMenuContainer = template_83f3139c928349b0948cd85b603306da(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
